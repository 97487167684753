export const options_select: any[] = [
  {
    name: "ENG",
    img: "/assets/img/icon-us.png",
  },
  {
    name: "ESP",
    img: "/assets/img/icon-esp.png",
  },
];
