import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Project } from "src/app/core/models/project.interface";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  @Input() project: Project;
  @Input() isProduct: boolean = false;
  newProject: Project;

  constructor(private router: Router) {}

  ngOnInit() {
    this.modifyDescription();
  }

  modifyDescription(): Project {
    return (this.newProject = {
      ...this.project,
      description: this.project.description.replace(/\"/g, ""),
    });
  }
  navigateTo(link: string): void {
    let url = this.isProduct ? '/products' : '/projects';
    url = url + '/' + link;
    localStorage.setItem('lastSection', 'Portfolio');
    localStorage.setItem('currentTab', this.isProduct ? 'products' : 'innovation');
    this.router.navigate([url]);
  }
}
