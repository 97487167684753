import { UseCases } from "../../app/core/models/use-cases.interface";

export const project_use_cases: UseCases[] = [
  {
    project: "3D Reality",
    use_cases: [
      { name: "A", solutions: ["Virtual Host at office Entrance"] },
      { name: "B", solutions: ["Virtual Host at office Entrance"] },
      { name: "C", solutions: ["Virtual Host at office Entrance"] },
      { name: "D", solutions: ["Virtual Host at office Entrance"] },
      {
        name: "E",
        solutions: [
          "Virtual Host at Hospitals or clinics receptions in order to help with appointments, reschedule and general help",
        ],
      },
      { name: "F", solutions: ["Virtual Host at office Entrance"] },
    ],
  },
  {
    project: "AR Navigation",
    use_cases: [
      {
        name: "A",
        solutions: [
          "To help navigate through business offices, offering a voice assistant for vision-impaired people",
        ],
      },
      {
        name: "B",
        solutions: ["To help visitors find a meeting room within a bank"],
      },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: [
          "To help navigate through shopping malls",
          "To help navigate through airports and find a specific boarding gate",
          "To help navigate through stations and find a train/bus platform",
        ],
      },
      {
        name: "E",
        solutions: ["To help visitors to get to a specific room or office"],
      },
      {
        name: "F",
        solutions: [
          "To help technicians navigate through large electrical facilities",
        ],
      },
    ],
  },
  {
    project: "Attendance Manager",
    use_cases: [
      { name: "A", solutions: [] },
      { name: "B", solutions: [] },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: [
          "Stores that organize marketing events, will be able to easily manage the attending guests",
        ],
      },
      { name: "E", solutions: [] },
      { name: "F", solutions: [] },
    ],
  },
  {
    project: "Time Up",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Time tracking solution for employees",
          "Measure how much time a technician uses to fix an issue, which improves the repair-time estimations",
        ],
      },
      { name: "B", solutions: ["Time tracking solution for employees"] },
      { name: "C", solutions: ["Time tracking solution for employees"] },
      { name: "D", solutions: ["Time tracking solution for employees"] },
      { name: "E", solutions: ["Time tracking solution for employees"] },
      { name: "F", solutions: ["Time tracking solution for employees"] },
    ],
  },
  {
    project: "The AR Help APP",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Internet customer support from a skilled technician",
          "Offer technicians a way to resolve each other's questions",
        ],
      },
      { name: "B", solutions: [] },
      {
        name: "C",
        solutions: [
          "Roadside assistance to fix car breakdowns",
          "House insurance companies can contact their customers to help them fix or detect potential issues",
          "Offer a first damage money evaluation after a problem (car accident or house issues) has happened",
        ],
      },
      {
        name: "D",
        solutions: [
          "Allow companies (such as MediaMarkt, El Corte Inglés, Fnac, etc.) offer a premium AR customer support service that doesn't require customers to go to the store nor send the device/appliance.",
        ],
      },
      {
        name: "E",
        solutions: [
          "Allow doctors to get a second opinion to improve diagnosis ",
          "Improve teaching by removing the limitations of space, so students receive direct feedback from the doctor's POV",
          "Connect with reputated docturs during important operations to receive their feedback",
        ],
      },
      {
        name: "F",
        solutions: [
          "Help support teams fix issues on-site with remote help",
          "Contact the customer for direct support",
        ],
      },
    ],
  },
  {
    project: "3D Plan Generator",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Videogame creators will be able to create pixel-perfect maps just from a 2D representation",
        ],
      },
      { name: "B", solutions: [] },
      { name: "C", solutions: [] },
      { name: "D", solutions: [] },
      { name: "E", solutions: [] },
      {
        name: "F",
        solutions: [
          "Architects and construction companies can easily convert their 2D plans to 3D models",
        ],
      },
    ],
  },
  {
    project: "Interior Planner",
    use_cases: [
      { name: "A", solutions: [] },
      {
        name: "B",
        solutions: [
          "Help establish a brand-based common design on all the offices",
          "For the real-estate department of banks, they can offer customers a new way to see and experience the apartments they have available for sale",
        ],
      },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: [
          "Help establish a brand-based common design on all stores",
          "Real-estate companies (such as Fotocasa, Idealista,etc) can offer their customers a way to decorate an empty apartment before buying or renting it",
        ],
      },
      { name: "E", solutions: [] },
      {
        name: "F",
        solutions: [
          "When designing a new center/factory, it could help improve the distribution",
        ],
      },
    ],
  },
  {
    project: "AR TV Picker",
    use_cases: [
      { name: "A", solutions: [] },
      { name: "B", solutions: ["Improve interior redesigning"] },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: [
          "Improve any home appliances or electronics purchasing experience",
        ],
      },
      { name: "E", solutions: [] },
      { name: "F", solutions: [] },
    ],
  },
  {
    project: "Card Scanner",
    use_cases: [
      { name: "A", solutions: [] },
      {
        name: "B",
        solutions: ["Display information about your credit card"],
      },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: ["Display information about your loyalty card"],
      },
      {
        name: "E",
        solutions: [
          "Display information about your heath history: reports, analysis, previous visits, etc.",
        ],
      },
      { name: "F", solutions: [] },
    ],
  },
  {
    project: "AR Experience",
    use_cases: [
      { name: "A", solutions: [] },
      { name: "B", solutions: [] },
      { name: "C", solutions: [] },
      {
        name: "D",
        solutions: [
          "Enhanced Ad&Marketing campaigns with dynamic animations coming out of a poster",
          "Preview if a car can fit inside a garage",
          "Try out products such as glasses before buying them",
        ],
      },
      {
        name: "E",
        solutions: [
          "Awareness campaigns that show the effects of smoking and other bad habits in AR",
        ],
      },
      {
        name: "F",
        solutions: ["Preview how a new building would look on a site"],
      },
    ],
  },
  {
    project: "Scan & Block",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Corporate devices with active detection to avoid confidential data sharing and leaks",
          "Improved child content filter",
        ],
      },
      { name: "B", solutions: [] },
      { name: "C", solutions: [] },
      { name: "D", solutions: [] },
      { name: "E", solutions: [] },
      { name: "F", solutions: [] },
    ],
  },
  {
    project: "Vigilant",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Enhance the customer support that tech companies offer, that allows the customer to know where the technician is and how long it will take him to arrive",
          "Smart speaker application that offers product information and management, such as phone rates and produt hiring",
        ],
      },
      {
        name: "B",
        solutions: [
          "Smart speaker application that offer customers the possibility to manage their accounts",
        ],
      },
      {
        name: "C",
        solutions: [
          "Enhanced customer support for insurance companies that allows the customer to receive helpful information through a smart speaker, as well as knowing where the mechanic is and how much will it take him to arrive",
        ],
      },
      {
        name: "D",
        solutions: [
          "Smart speaker application that allows customers to order food and get product information",
        ],
      },
      {
        name: "E",
        solutions: [
          "Medical assistant in contact with professionals ",
          "In hospitals, it could save time to inform the staff about emergencies",
        ],
      },
      {
        name: "F",
        solutions: [
          "Smart speaker application that connects with the customer's light-company account and offers valuable information such as consumed energy or billing rates",
        ],
      },
    ],
  },
  {
    project: "Insurance Bot Assistant",
    use_cases: [
      {
        name: "A",
        solutions: [
          "Allow tech companies to have a bot that serves as first level support for customer inquiries",
        ],
      },
      {
        name: "B",
        solutions: [
          "Offer information about new bank accounts and reduce product hiring time",
          "Send custom offers about loans to each customer",
        ],
      },
      {
        name: "C",
        solutions: [
          "Offer information about new policies and reduce product hiring time",
          "Send custom offers about premium features to each customer",
        ],
      },
      {
        name: "D",
        solutions: [
          "Enhance the shopping experience: from custom offers to stock information about products",
        ],
      },
      {
        name: "E",
        solutions: [
          "Receive personal information (such as reports) directly in your phone",
          "Schedule meetings with doctors",
        ],
      },
      {
        name: "F",
        solutions: [
          "Offer information about the customer's account and reduce product hiring time",
          "Send custom offers about rates to each customer",
        ],
      },
    ],
  },
];
