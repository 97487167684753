import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { formFields } from "./enums/form-fields.enum";
import { ContactForm } from "./models/contact-form.interface";

declare const $: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  type: string;
  messageText: string;
  buttonDisabledForm: FormGroup;
  ImgSrc: string;
  ImgAlt: string;
  nameClicked: boolean = false;
  emailClicked: boolean = false;
  subjectClicked: boolean = false;
  messageClicked: boolean = false;

  formData: FormGroup;

  get name() {
    return this.formData.get("name");
  }

  get email() {
    return this.formData.get("email");
  }

  get subject() {
    return this.formData.get("subject");
  }

  get message() {
    return this.formData.get("message");
  }

  get UpNameLabel(): boolean {
    return this.nameClicked || this.name.value.length >= 1;
  }

  get UpEmailLabel(): boolean {
    return this.emailClicked || this.email.value.length >= 1;
  }

  get UpSubjectLabel(): boolean {
    return this.subjectClicked || this.subject.value.length >= 1;
  }

  get UpMessageLabel(): boolean {
    return this.messageClicked || this.message.value.length >= 1;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.type = "submit";
    this.messageText = "Send message";
    this.ImgSrc = "assets/img/arrow-right.png";
    this.ImgAlt = "submit-arrow";
    this.initForm();
    this.buttonDisabledForm = this.formData;
    window.addEventListener("scroll", this.onscroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.onscroll, true);
  }

  onscroll = (): void => {
    let mybutton = document.getElementById("goUp");

    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  };

  scroll(el: string) {
    $("header#" + el)[0].scrollIntoView();
  }

  submit(formValues: ContactForm): void {
    //TODO: Make post to send email
  }

  isFieldClicked(e: any): void {
    switch (e.target.name) {
      case formFields.F01:
        this.nameClicked = !this.nameClicked;
        break;

      case formFields.F02:
        this.emailClicked = !this.emailClicked;
        break;

      case formFields.F03:
        this.subjectClicked = !this.subjectClicked;
        break;

      case formFields.F04:
        this.messageClicked = !this.messageClicked;
        break;

      default:
        break;
    }
  }

  private initForm() {
    if (!this.formData) {
      this.formData = this.fb.group({
        name: ["", [Validators.required, Validators.minLength(4)]],
        email: ["", [Validators.required, Validators.email]],
        subject: ["", [Validators.required, Validators.minLength(8)]],
        message: ["", [Validators.required, Validators.minLength(8)]],
      });
    }
  }
}
