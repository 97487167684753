import { Product } from "../../app/core/models/product.interface";

export const products_data: Product[] = [
  {
    order: 1,
    link: "pulse",
    name: "Pulse",
    description: '<p>"Activate your audience <b>in real time</b>"</p>',
    image: "/assets/img/previews/pulse.jpg",
    firstImg: "/assets/img/pulse/pulse-message.jpg",
    descriptionText:
      "UST Pulse wants to help you boost the user engagement of your meetings. Gather feedback in real time was never this easy, our set of tools available keeps growing with each new version.",
    breakdownListImg: "/assets/img/pulse/pulse-icon.png",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "With Pulse you can bring your meetings and events to the next level",
      "Gather feedback in real time to understand the parts of presentation that worked best",
      "Allow your audience to ask questions, and track the ones you answer.",
      "Create any type of survey for your users.",
      "Play with your audience by creating ruffles",
    ],
    breakdownImg: "/assets/img/pulse/pulse-breakdown.jpg",
    fullImg: "/assets/img/pulse/pulse-full.jpg",
  },
  {
    order: 2,
    link: "stepOne",
    name: "StepOne",
    description:
      '<p>"Don\'t start your <b>next project</b> from scratch, we can help you!"</p>',
    image: "/assets/img/previews/stepOne.jpg",
    firstImg: "/assets/img/stepOne/stepOne-message.jpg",
    descriptionText:
      "We bring you a set of tools and frameworks to speed up your future developments. Create new templates with our web wizard in just minutes and forget about the hassle of configuring new projects",
    breakdownListImg: "/assets/img/stepOne/stepOne-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Set of tools to speed up your future developments.",
      "A component library to facilitate UI projects.",
      "Build new project templates using our web wizard.",
      "Support for multiple programming languages that can be combined following yur needs.",
    ],
    breakdownImg: "/assets/img/stepOne/stepOne-breakdown.jpg",
    fullImg: "/assets/img/stepOne/stepOne-full.jpg",
  },
  {
    order: 3,
    link: "neocortex",
    name: "Neocortex",
    description: '<p>"Welcome to your new <b>Machine Learning sandbox</b>"</p>',
    image: "/assets/img/previews/neocortex.jpg",
    firstImg: "/assets/img/neocortex/neocortex-message.jpg",
    descriptionText:
      "Building predictive models was never easier. With our sandbox solution anyone that has a dataset can start training new models. It's as simple as specifying the entries and outputs of your desire model.",
    breakdownListImg: "/assets/img/neocortex/neocortex-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Auto machine learning, well known as AML is the future of AI.",
      "We provide a very simple tool where anyone can start training predictive models.",
      "We can work with any dataset, supporting these type of parameters (numbers, strings, categories, binary and many more in future updates).",
    ],
    breakdownImg: "/assets/img/neocortex/neocortex-breakdown.jpg",
    fullImg: "/assets/img/neocortex/neocortex-full.jpg",
  },
  {
    order: 4,
    link: "peopleFinder",
    name: "People Finder",
    description:
      '<p>"Organizing your <b>company\'s talent</b> has never been easier"</p>',
    image: "/assets/img/previews/people-finder.jpg",
    firstImg: "/assets/img/people-finder/people-finder-message.jpg",
    descriptionText:
      "Hiring the right talent is always difficult but finding that talent in house is even more complex. We bring you the perfect tool to classify your team based on their skills so finding the right fit won't be a problem anymore.",
    breakdownListImg: "/assets/img/people-finder/people-finder-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Search employees based on their skills level.",
      "Export standardized curriculums all from one place.",
      "Analyse the base knowledge of your company to prepare future trainings.",
    ],
    breakdownImg: "/assets/img/people-finder/people-finder-breakdown.jpg",
    fullImg: "/assets/img/people-finder/people-finder-full.jpg",
  },
  {
    order: 5,
    link: "ustCandidates",
    name: "UST Candidates",
    description:
      '<p>"Take advantage of our platform to speed up <b>technical skills validations</b>"</p>',
    image: "/assets/img/previews/ust-candidates.jpg",
    firstImg: "/assets/img/ust-candidates/ust-candidates-message.jpg",
    descriptionText:
      "We take our screening processes very seriously to give our clients the best team available. With this solution the validation of their skills is simple and automated.",
    breakdownListImg: "/assets/img/ust-candidates/ust-candidates-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Finding the right talent is not an easy task, and is always very time consuming.",
      "With our solution, you can technically validate your candidates in a very simple and convenient way.",
      "Create tests for any soft or technical skill.",
      "Validate as many skills as you need in one single process.",
    ],
    breakdownImg: "/assets/img/ust-candidates/ust-candidates-breakdown.jpg",
    fullImg: "/assets/img/ust-candidates/ust-candidates-full.jpg",
  },
  {
    order: 6,
    link: "itaca",
    name: "Itaca Insurance Care",
    description:
      '<p>"Get ready for present and future challenges in the <b>insurance world!</b>"</p>',
    image: "/assets/img/previews/itaca.jpg",
    firstImg: "/assets/img/itaca/itaca-message.jpg",
    descriptionText:
      "Meet our new Core Insurance built from scratch, using the latest tech stack and covering the most requested features among our clients.",
    breakdownListImg: "/assets/img/itaca/itaca-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Discover the solution that is helping many insurance companies from around the world along the exciting path of Digital Transformation.",
      "Improved efficiency.",
      "Cutting edge technology.",
      "Reduce time to market.",
      "Customer centric",
      "Cloud solution",
    ],
    breakdownImg: "/assets/img/itaca/itaca-breakdown.jpg",
    fullImg: "/assets/img/itaca/itaca-full.jpg",
  },
  {
    order: 8,
    link: "mebone",
    name: "Mebone",
    description: '<p>"Innovation and efficiency on <b>fraud detection</b>"</p>',
    image: "/assets/img/previews/mebone.jpg",
    firstImg: "/assets/img/mebone/mebone-message.jpg",
    descriptionText:
      "With our decision engine detecting fraud was never easier. We have opened the platform to be trained in any potential use case needed and not just fraud.",
    breakdownListImg: "/assets/img/mebone/mebone-icon.jpg",
    subList: false,
    subListFirstHeader: "",
    subListFirstList: [],
    subListSecondList: [],
    subListSecondHeader: "",
    subListImg: "",
    breakdownList: [
      "Mebone can be trained to detect fraud among other uses cases, fully customised.",
      "Analyse real time or in planned batches all incoming transactions.",
      "Configure different detection strategies, supervised and unsupervised, to trigger all kind of alerts.",
      "Customised informs for futher analysis.",
    ],
    breakdownImg: "/assets/img/mebone/mebone-breakdown.jpg",
    fullImg: "",
  },
  {
    order: 7,
    link: "marketplace",
    name: "Marketplace",
    description: '<p>"One platform, <b>many uses</b> cases"</p>',
    image: "/assets/img/previews/marketplace.jpg",
    firstImg: "/assets/img/marketplace/marketplace-message.jpg",
    descriptionText:
      "There are many platforms out there, but we truly believe is not possible to cover every scenario with just one platform. That's why we have built a modular marketplace that offers the best capabilities for each use case.",
    breakdownListImg: "",
    breakdownList: [
      "Our marketplace solutions allow you to create many different use cases, supporting multiple business models.",
    ],
    subList: true,
    subListFirstHeader: "our business model",
    subListFirstList: [
      "Saas (license)",
      "Revenue share",
      "Sign up free",
      "Subscription",
    ],
    subListSecondList: ["Product listing", "Advertising", "Data selling"],
    subListSecondHeader: "our business model",
    subListImg: "/assets/img/marketplace/marketplace-list-img.jpg",
    breakdownImg: "/assets/img/marketplace/marketplace-breakdown.jpg",
    fullImg: "/assets/img/marketplace/marketplace-full.jpg",
  },
];
