import { Component, Input } from "@angular/core";

@Component({
  selector: "app-back-button",
  template: ` <div class="row">
    <div
      class="back"
      [ngClass]="{
        backEmbed: isBackEmbed,
        noBackColor: noBackColor
      }"
    >
      <button class="btn" [routerLink]="['/']">
        <i class="fa fa-chevron-left"></i>
        BACK
      </button>
    </div>
  </div>`,
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent {
  @Input() isBackEmbed: boolean = false;
  @Input() noBackColor: boolean = false;
}
