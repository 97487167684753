import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../../core/services/data.service";
import { Project } from "../../../core/models/project.interface";
import { Product } from "src/app/core/models/product.interface";

@Component({
  selector: "app-more-projects",
  templateUrl: "./more-projects.component.html",
  styleUrls: ["./more-projects.component.scss"],
})
export class MoreProjectsComponent implements OnInit {
  projectsParam: Project[];
  productsParam: Product[];
  projects: (Project | Product)[];
  link: string;
  href: string = "";
  urlArray: string[] = [];
  isProduct: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.link = params["link"];
      this.href = this.router.url;

      this.isProduct = this.router.url.includes("products");

      if (!this.isProduct) {
        this.projectsParam = this.data.getProjects();
        this.projects = this.data.getRelatedProjectsAndProducts(
          this.link,
          this.projectsParam
        );
      } else {
        this.productsParam = this.data.getProducts();
        this.projects = this.data.getRelatedProjectsAndProducts(
          this.link,
          this.productsParam
        );
      }
    });
  }
}
