import { Project } from "../../app/core/models/project.interface";

export const projects_data: Project[] = [
  {
    name: "Vigilant",
    link: "vigilant",
    description: "Bringing people in need and caregivers together",
    image: "/assets/img/previews/vigilant.jpg",
    types: ["smart", "mobile", "solution"],
    order: 2,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">VIGILANT</div>
          <div class="subtitle-project">Vigilant is a platform both for people in need and caregivers, that allows them to report or assist to an emergency
          quickly and in a convenient way.​</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex align-items-end">
        <img src="/assets/img/vigilant/group-3.png" srcset="/assets/img/vigilant/group-3@2x.png 2x,
                      /assets/img/vigilant/group-3@3x.png 3x" class="group-img mx-auto mx-lg-0 ml-5">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3 d-flex justify-content-between">
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-9.png" srcset="/assets/img/vigilant/group-9@2x.png 2x,
                      /assets/img/vigilant/group-9@3x.png 3x" class="group-img">
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-8.png" srcset="/assets/img/vigilant/group-8@2x.png 2x,
                      /assets/img/vigilant/group-8@3x.png 3x" class="group-img">
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-7.png" srcset="/assets/img/vigilant/group-7@2x.png 2x,
                      /assets/img/vigilant/group-7@3x.png 3x" class="group-img">
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-6.png" srcset="/assets/img/vigilant/group-6@2x.png 2x,
                      /assets/img/vigilant/group-6@3x.png 3x" class="group-img">
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-5.png" srcset="/assets/img/vigilant/group-5@2x.png 2x,
                                /assets/img/vigilant/group-5@3x.png 3x" class="group-img">
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-12 pb-3">
        <div class="text-center">
          <img src="/assets/img/vigilant/group-4.png" srcset="/assets/img/vigilant/group-4@2x.png 2x,
                                  /assets/img/vigilant/group-4@3x.png 3x" class="group-img">
        </div>
      </div>
    </div>
    <div class="row py-5 overflow-auto">
      <div class="col-12 text-center">
        <iframe width="536" height="315" src="https://www.youtube.com/embed/PsXaSuzjpx4">
        </iframe>
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    references_es: true,
    references_en: true,
    presentation_2020: true,
  },
  {
    name: "Telefonica Security Dashboard",
    link: "telefonica-security-dashboard",
    description:
      "Control panels that show the risk that a mobile device can expose with the applications it has installed",
    image: "/assets/img/previews/telefonica.jpg",
    types: ["mobile", "solution"],
    order: 1,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <img class="py-1" src="/assets/img/telefonica/logoTelefonica.png" alt="telefonica-logo.png" width="109">​
          <div class="category">DASHBOARD</div>
          <div class="title-project">Telefónica Security Dashboard</div>
          <div class="subtitle-project">Control panels that show the risk that a mobile device can expose with the
            applications it has installed.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 d-flex align-items-end">
        <img src="/assets/img/telefonica/image1.png" srcset="/assets/img/telefonica/image1@2x.png 2x,
                      /assets/img/telefonica/image1@3x.png 3x" class="group-img mx-auto mx-lg-0 ml-5">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 p-md-3 mx-auto">
        <div class="subtitle-project text-center py-3">Telefónica wants to develop a tool that is capable of
          analyzing the security of its devices during the certification
          process. This tool would be composed of three parts, an APK that will be installed in each of the devices
          that want to
          analyze, another that is responsible for receiving the data provided by the APK, a backend service, and an
          external one
          that is responsible for analyzing the APKs sent by the device. The APK will gather information about all
          the
          applications installed on the device, as well as all the connections made by these applications. All this
          data will be
          sent to the backend service that will archive and group the information.
        </div>
        <div class="subtitle-project text-center py-3">Two dashboards will also be available, which allow platform
          administrators to manage the devices and display statistics
          about them, respectively.
        </div>
        <div class="subtitle-project text-center py-3">
          <b>The first</b> will allow validating a device, which will cause the APKs of that device to be analyzed
          by the
          external
          service, which will provide risk levels of each of the applications, as well as the analysis of the
          connections received
          in search of some malicious domain.
        </div>
      </div>
      <div class="col-12 p-md-3">
        <img src="/assets/img/telefonica/telefonica1.png" srcset="/assets/img/telefonica/telefonica1@2x.png 2x,
                                  /assets/img/telefonica/telefonica1@3x.png 3x" class="group-img p-3">
        <img src="/assets/img/telefonica/telefonica2.png" srcset="/assets/img/telefonica/telefonica2@2x.png 2x,
                                                          /assets/img/telefonica/telefonica2@3x.png 3x"
          class="group-img p-3">
      </div>
      <div class="col-12 col-md-8 mx-auto">
        <div class="subtitle-project text-center p-md-3 py-3">
          <b>The second</b> one will show the validated devices grouped by brand, country or APK. What allows to
          have a global vision of
          all the analyzed devices, the brands with a higher risk, the countries with more level of risk or the APK
          potentially
          dangerous for safety.

        </div>
      </div>
      <div class="col-12 p-md-3">
        <img src="/assets/img/telefonica/telefonica3.png" srcset="/assets/img/telefonica/telefonica3@2x.png 2x,
                                                                      /assets/img/telefonica/telefonica3@3x.png 3x"
          class="group-img p-3">
      </div>
    </div>
  </div>
</section>
`,
    references_es: true,
    references_en: true,
  },
  {
    name: "Scan & Block",
    link: "scan-and-block",
    description: "Scan, identify and block ítems on your screen",
    image: "/assets/img/previews/scan-and-block.jpg",
    types: ["mobile", "solution"],
    order: 5,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">SCAN & BLOCK</div>
          <div class="subtitle-project">Choose the items you want to block, and these will be blocked on any device
            screen.</div>
          <div class="subtitle-project">First of all, we take the images that are going to be painted on the screen
            of the phone, using a media projector, a
            function that is provided by the Android operating system itself.
            Transformation of images that come out of the media projector is done so that they are compatible with
            modern machine
            learning tools. Through a trained model we can recognize the object we want and allow us to identify the
            position in
            which the object is on the screen.</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex align-items-end">
        <img src="/assets/img/scan-and-block/scanblock.png" srcset="/assets/img/scan-and-block/scanblock@2x.png 2x,
                      /assets/img/scan-and-block/scanblock@3x.png 3x" class="group-img mx-auto mx-lg-0 ml-5">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 p-md-3 mx-auto">
        <div class="subtitle-project text-center py-3">Finally, we apply a layer that hides the detected element.
          This issue causes the problem that makes us reach our
          solution, because when an object is covered the media projector also generates it covered, so we had to
          modify the
          Android OS generating its own OS, capable of ignoring the layer of lock and continue with the detection of
          objects
          uninterruptedly.
        </div>
      </div>
      <div class="col-12 col-lg-6 p-0">
        <img src="/assets/img/scan-and-block/machineLearningCopy3.png" srcset="/assets/img/scan-and-block/machineLearningCopy3@2x.png 2x,
                                  /assets/img/scan-and-block/machineLearningCopy3@3x.png 3x" class="group-img">
      </div>
      <div class="col-12 col-lg-6 p-0">
        <img src="/assets/img/scan-and-block/machineLearningCopy5.png" srcset="/assets/img/scan-and-block/machineLearningCopy5@2x.png 2x,
                                                                      /assets/img/scan-and-block/machineLearningCopy5@3x.png 3x"
          class="group-img">
      </div>
    </div>
    <div class="row py-5 overflow-auto">
      <div class="col-12 text-center">
        <iframe width="536" height="315" src="https://www.youtube.com/embed/jAdu8Slh7j8">
        </iframe>
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    presentation_2020: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "Enagas",
    link: "enagas",
    description:
      "Application that offers information about the Spanish Gas System",
    image: "/assets/img/previews/enagas.jpg",
    types: ["mobile", "ux"],
    order: 4,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <img class="py-1" src="/assets/img/enagas/logoEnagasRgbPositivoRgb3.png" alt="enagas-logo.png" width="70">​
          <div class="category">APP</div>
          <div class="title-project">ENAGAS</div>
          <div class="subtitle-project">Application that offers real time information about the Spanish National Gas
            System. We have developed an app for
            Enagas, the Spanish energy company, which owns and operates the nation's gas grid.
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex align-items-end">
        <img src="/assets/img/enagas/enagas.gif" class="group-img mx-auto mx-lg-0 ml-5">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7 p-0">
        <img src="/assets/img/enagas/appleIPadAir2SpaceGray.png" srcset="/assets/img/enagas/appleIPadAir2SpaceGray@2x.png 2x,
                                  /assets/img/enagas/appleIPadAir2SpaceGray@3x.png 3x" class="group-img my-auto">
      </div>
      <div class="col-12 col-lg-5 p-md-3 mx-auto">
        <div class="subtitle-project">This application allows the user to check the latest data of
          the
          Spanish Gas System, such as:
        </div>
        <div class="subtitle-project">
          1. Real-time instantaneous flows at entry points to the Virtual Trading Point (Punto Virtual de Balance,
          PVB):
          Production at regasification plants, entry/exit flows at international connections, injection/withdrawal
          in
          underground
          storage, biomethane production and gas fields production.
        </div>
        <div class="subtitle-project">
          2. The hourly gas natural demand and its forecast for the next hours. Conventional demand includes the
          industrial
          sector, the domestic-commercial sector one. The total demand includes conventional, truck loading and
          electrical
          sector.
        </div>
        <div class="subtitle-project">
          3. The predicted closing linepack within the Transmission Network at the end of the current gas day wich
          is updated
          hourly.
        </div>
        <div class="subtitle-project">
          4. The average value of the conversion factor applicable to your invoice.
        </div>
        <div class="subtitle-project">
          In addition, from the Enagas EnergyData APP you can get informed about the latest news from Enagás and the
          events it
          organizes for professionals in the gas sector.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 text-center">
        <img src="/assets/img/enagas/enagas1.png" srcset="/assets/img/enagas/enagas1@2x.png 2x,
                                  /assets/img/enagas/enagas1@3x.png 3x" class="group-img my-auto">
      </div>
      <div class="col-12 col-md-6 col-lg-3 text-center">
        <img src="/assets/img/enagas/enagas2.png" srcset="/assets/img/enagas/enagas2@2x.png 2x,
                                  /assets/img/enagas/enagas2@3x.png 3x" class="group-img my-auto">
      </div>
      <div class="col-12 col-md-6 col-lg-3 text-center">
        <img src="/assets/img/enagas/enagas3.png" srcset="/assets/img/enagas/enagas3@2x.png 2x,
                                  /assets/img/enagas/enagas3@3x.png 3x" class="group-img my-auto">
      </div>
      <div class="col-12 col-md-6 col-lg-3 text-center">
        <img src="/assets/img/enagas/enagas4.png" srcset="/assets/img/enagas/enagas4@2x.png 2x,
                                  /assets/img/enagas/enagas4@3x.png 3x" class="group-img my-auto">
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: true,
  },
  {
    name: "Interior Planner",
    link: "interior-planner",
    description: "Design your space!",
    image: "/assets/img/previews/interior-planner.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 8,
    body: `<section>
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-12 pl50 my-auto">
      <div class="text-right py-3">
        <div class="category">APP</div>
        <div class="title-project">INTERIOR PLANNER</div>
        <div class="subtitle-project">Interior Planner is a multi-tool app designed to offer the user a unique
          design
          experience. A lot of tools and
          possibilities unified in a simple and intuitive interface.</div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 pr50">
      <img src="/assets/img/interior-planner/interiorPlanner@2x.png" alt="interior-planeer-logo.png">
    </div>
  </div>
</div>
</section>
<section class="back-color py-5">
<div class="container">
  <div class="row">
    <div class="col-lg-7 col-md-12">
      <img src="/assets/img/interior-planner/group-2.png" srcset="/assets/img/interior-planner/group-2@2x.png 2x,
                    /assets/img/interior-planner/group-2@3x.png 3x" class="group-img">
    </div>
    <div class="col-lg-5 col-md-12 my-auto">
      <div class="py-3">
        <div class="subtitle-project">Just select a furniture from our catalog and put it wherever you want. You can
          replicate it, move it and orient it by
          all your space easily. Don't forget to use our measurement tool, it will make your job much easier!
        </div>
        <div class="subtitle-project">
          Do not limit only to the available catalog. You can build your own objects, from pillars, walls, and
          entire
          rooms with
          geometric figures to your own marketing pieces with images of your own gallery. Don't leave your space
          without
          the logo
          of your company!</div>
      </div>
    </div>
  </div>
</div>
</section>

<section class="py-5">
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <div class="subtitle-project">Want to show someone your designs? Or just save it for the future? Interior
          Planner offers that possibility. Just save
          your set and it will be added to your furniture menu. With a single touch, you will be able to put your
          entire
          construction at once. Take your favorite designs wherever you want!
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <img src="/assets/img/interior-planner/mockup.png" srcset="/assets/img/interior-planner/mockup@2x.png 2x,
                    /assets/img/interior-planner/mockup@3x.png 3x" class="group-img">
    </div>
  </div>
</div>
</section>`,
    presentation: true,
    presentation_2020: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "Card Scanner",
    link: "card-scanner",
    description:
      "Visualize your card movements, accounts and stats right from your device camera",
    image: "/assets/img/previews/card-scanner.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 6,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">CARD SCANNER</div>
          <div class="subtitle-project">The Card Scanner SDK is a solution that allows smartphone users to access
            their personal finances through an immersive
            Augmented Reality experience.
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex align-items-end">
        <img src="/assets/img/card-scanner/portadaCardscannewr2.png" srcset="/assets/img/card-scanner/portadaCardscannewr2@2x.png 2x,
                                                  /assets/img/card-scanner/portadaCardscannewr2@3x.png 3x"
          class="group-img"></div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 p-md-3 mx-auto text-center">
        <div class="subtitle-project">Augmented Reality has been growing exponentially the last years, new creative AR solutions are stirring up the market
        and gaining the trust and interest of users worldwide.
        </div>
        <div class="subtitle-project">
          We think the finance sector can really take advantage of this technology, that’s why we are developing solutions like
          Card Scanner.
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-md-4 col-12 text-center text-md-right mt-auto pb-3">
        <div class="subtitle-project">Our SDK can scan the card and present the corresponding information to the
          user
          through an AR layer. From account
          balance to latest movements, including transaction information or dynamic charts, users are able to
          interact, manage and
          visualize their finances in a fast and convenient way.
        </div>
      </div>
      <div class="col-md-8 col-12 text-center">
        <img src="/assets/img/card-scanner/cardscannergroup.png" srcset="/assets/img/card-scanner/cardscannergroup@2x.png 2x,
                                      /assets/img/card-scanner/cardscannergroup@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "Connected Car",
    link: "connected-car",
    description:
      "See the information of your car collected through an IoT device on your vehicle",
    image: "/assets/img/previews/movistar-car.jpg",
    types: ["mobile", "iot"],
    order: 7,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 my-auto">
        <div class="text-right py-3">
          <img class="py-1" src="/assets/img/telefonica/logoTelefonica.png" alt="telefonica-logo.png" width="109">​
          <div class="category">APP</div>
          <div class="title-project">CONNECTED CAR</div>
          <div class="subtitle-project">Example of integration, development for a decoder of a TV service,
            showing the information of your car on your TV.</div>
          <div class="subtitle-project">
            Telefonica asked us to develop an application for the decoder of its famous Movistar + service, which
            offers live TV and
            video streaming services. The application would consist of a service which would be consumed by the
            decoder itself,
            where, we would authenticate ourselves by means of the telephone, in which we received an SMS to verify
            our identity.
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 my-auto py-2">
        <img src="/assets/img/connected-car/group-2.png" srcset="/assets/img/connected-car/group-2@2x.png 2x,
                      /assets/img/connected-car/group-2@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <img src="/assets/img/connected-car/car-2.png" srcset="/assets/img/connected-car/car-2@2x.png 2x,
                      /assets/img/connected-car/car-2@3x.png 3x" class="group-img">
      </div>
      <div class="col-lg-6 col-md-12 my-auto">
        <div class="">
          <div class="subtitle-project">Once we have accessed, the service is integrated with Net4Things, which
            provides the data received by the
            self-installing device ODB II, compatible with all cars manufactured from 2002. This device also offers
            a Wi-Fi hotspot
            for the interior of the vehicle as well as collecting the data generated by the car, such as speed,
            geopositioning,
            state.​
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 my-auto">
        <div class="text-center text-md-right">
          <div class="subtitle-project">With all these data we can offer the user from your home TV the possibility
            of seeing where you have parked, your
            routes, as well as, see the rest of your cars connected, this last functionality is not yet developed
            but is a goal for
            a second phase.
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <img src="/assets/img/connected-car/car-1.png" srcset="/assets/img/connected-car/car-1@2x.png 2x,
                                  /assets/img/connected-car/car-1@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: true,
  },
  {
    name: "Quality Data for Endesa",
    link: "endesa-quality-data",
    description: "Customer quality data compilation for Endesa",
    image: "/assets/img/previews/endesa-quality-data.jpg",
    types: ["ux"],
    order: 2,
    body: `<section class="back-color">
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-12 pl50 my-auto">
      <div class="text-right py-3">
        <img class="py-1" src="/assets/img/endesa/endesa_logo.png" alt="endesa-logo.png" width="109">​
        <div class="category">DASHBOARD</div>
        <div class="title-project">QUALITY DATA FOR ENDESA</div>
        <div class="subtitle-project">Customer quality data compilation for Endesa.</div>
      </div>
    </div>
    <div class="col-lg-6 col-12 my-auto">
      <img class="py-2" src="/assets/img/endesa/quality.png" alt="quality.png">
    </div>
  </div>
</div>
</section>

<section class="py-5">
<div class="container">
  <div class="row">
    <div class="col-12">
    <div class="subtitle-project text-center py-3">Endesa had the need for a tool in which it could explore and globally visualize the satisfaction of its customers on their different channels, that is why with all the data from the surveys that are continuously made, we designed a dashboard where the most outstanding information is presented. An intuitive interface customizable and easy to read.
    </div>
    <div class="subtitle-project text-center py-3">It has been designed with the functionalities offered by Einstein Analytics, App Builder and Lightning Service Design by Salesforce.
    </div>
    <div class="text-center">
      <img style="width: 885px;" src="/assets/img/endesa/CCDD DESKTOP.png" alt="engas.PNG" class="img-conv-alexa">
      <br>
    </div>​
    <div class="text-center">
      <img style="width: 885px;" src="/assets/img/endesa/CCDD ficha de cliente.png" alt="engas.PNG" class="img-conv-alexa">
      <br>
    </div>​
    <div class="text-center">
      <img src="/assets/img/endesa/CCDD MOBILE.png" alt="engas.PNG" class="img-conv-alexa">
      <br>
    </div>​
    <div class="text-center">
      <img style="width: 885px;" src="/assets/img/endesa/CCDD version a.png" alt="engas.PNG" class="img-conv-alexa">
      <br>
    </div>​
    <div class="text-center">
      <img style="width: 885px;" src="/assets/img/endesa/CCDD version b.png" alt="engas.PNG" class="img-conv-alexa">
      <br>
    </div>​
    </div>
  </div>
</div>
</section>`,
    references_es: true,
    references_en: true,
  },
  {
    name: "KPI’s in clients comunications",
    link: "endesa-kpis",
    description: "Data visualization of the KPI’s sended to the customer",
    image: "/assets/img/previews/endesa-kpis.jpg",
    types: ["ux"],
    order: 3,
    body: `
  <section class="back-color">
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-12 pl50 my-auto">
      <div class="text-right py-3">
        <img class="py-1" src="/assets/img/endesa/endesa_logo.png" alt="endesa-logo.png" width="109">​
        <div class="category">DASHBOARD</div>
        <div class="title-project">KPI’s in clients comunications</div>
        <div class="subtitle-project">Data visualization of the KPI’s sended to the customer.</div>
        <div class="subtitle-project">Endesa had a huge database with the KPI’s about the communications they send to their clients and needed to structure it.</div>
      </div>
    </div>
    <div class="col-lg-6 col-12 my-auto">
      <img src="/assets/img/endesa/endesa_kpis.gif" alt="monthly-goals-logo.png">
    </div>
  </div>
</div>
</section>

<section class="py-5">
<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div class="text-center">
        <div class="py-3 px-2">
          <img style="width: 885px;" src="/assets/img/endesa/HOME.png" alt="engas.PNG" class="img-conv-alexa">
        </div>​
      </div>
    </div>
    <div class="col-md-12 col-lg-6 my-auto">
      <div class="">
        <div class="subtitle-project">After analyzing the information they had, we design and built a dashboard with visual elements like charts and tooltips that help to go deeper into the monthly or weekly information.
The end of this project was to take better advantage of the feedback on the KPI’s results and get better revenues by taking better decisions on the channel they use to send their communications.
        </div>
      </div>
    </div>
  </div>
</div>
</section>`,
    references_es: true,
    references_en: true,
  },
  {
    name: "Monthly goals",
    link: "endesa-monthly-goals",
    description: "Endesa monthly goals Dashboard.",
    image: "/assets/img/previews/endesa-monthly-goals.jpg",
    types: ["ux"],
    order: 9,
    body: `<section class="back-color">
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-12 pl50 my-auto">
      <div class="text-right py-3">
        <img class="py-1" src="/assets/img/endesa/endesa_logo.png" alt="endesa-logo.png" width="109">​
        <div class="category">DASHBOARD</div>
        <div class="title-project">MONTHLY GOALS</div>
        <div class="subtitle-project">Endesa have different teams of sellers which they monitor their monthly goals, each group has different variables to measure and all of them have anual total achievement. At that time they used to report the data with a simple but hard to read table.</div>
      </div>
    </div>
    <div class="col-lg-6 col-12 my-auto">
      <div class="">
        <img src="/assets/img/endesa/dellXps13@2x.png" alt="monthly-goals-logo.png">
      </div>
    </div>
  </div>
</div>
</section>

<section class="py-5">
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <div class="subtitle-project">Thats why we designed a dashboard with the comparative of their individual goals and the monthly forecast. For this platform we based our widgets on Einstein Analytics, App Builder and Lightning Service Design by Salesforce.
        </div>
      </div>
    </div>
  </div>
</div>
</section>`,
    references_es: true,
    references_en: true,
  },
  {
    name: "AR TV Picker",
    link: "ar-tv-picker",
    description: "Find out if the TV you love fits in your home",
    image: "/assets/img/previews/ar-tv-picker.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 7,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">AR TV PICKER</div>
          <div class="subtitle-project">Using the latest AR technologies, AR TV Picker lets the user see how a
            specific TV would fit in their home space. Just
            choose any place or size and test it.</div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 pr-5">
        <img src="/assets/img/artv/i-phone-x-portada-mockup.png" srcset="/assets/img/artv/i-phone-x-portada-mockup@2x.png 2x,
                      /assets/img/artv/i-phone-x-portada-mockup@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <div class="subtitle-project">Our AR TV Picker solution is the perfect app to find out if the TV you’ve
            always loved, fits into the space that you
            have available at home. In the app you will find a catalogue of TV’s divided by model and maker. Each
            model has
            different features, sizes and descriptions, that help you find the TV you’ve always wanted to have.
            Once you have chosen the one that you love the most, you can start placing it in your room. For this,
            our solution
            allows you to decide whether you’d like to put it over a table or rather hang it on the wall. No matter
            which option you
            go for, you will be able to move it around to find a better spot to place it, change the TV inch-size to
            adjust it to
            your available space and, of course, buy the TV if it works great for you.
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-md-2 col-sm-4 text-center my-auto">
        <img src="/assets/img/artv/tv-list.png" srcset="/assets/img/artv/tv-list@2x.png 2x,
                    /assets/img/artv/tv-list@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-2 col-sm-4 text-center my-auto">
        <img src="/assets/img/artv/tv-detail.png" srcset="/assets/img/artv/tv-detail@2x.png 2x,
                    /assets/img/artv/tv-detail@3x.png 3x" class="group-img"></div>
      <div class="col-md-2 col-sm-4 text-center my-auto">
        <img src="/assets/img/artv/taman-o-iphone-6.png" srcset="/assets/img/artv/taman-o-iphone-6@2x.png 2x,
                    /assets/img/artv/taman-o-iphone-6@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-2 col-sm-4 text-center my-auto">
        <img src="/assets/img/artv/tipo-de-tv-iphone-6.png" srcset="/assets/img/artv/tipo-de-tv-iphone-6@2x.png 2x,
                    /assets/img/artv/tipo-de-tv-iphone-6@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-3 col-sm-4 text-center my-auto">
        <img src="/assets/img/artv/group.png" srcset="/assets/img/artv/group@2x.png 2x,
                                /assets/img/artv/group@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>

<section class="pb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="py-5 text-center">
          <div class="subtitle-project">This is just an example of how we can improve the experience when buying a
            television, but, if you want to see how you
            can furnish your home, visit <a href="/projects/Interior Planner">INTERIOR PLANNER</a>.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
`,
    presentation: true,
    presentation_2020: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "AR Experience",
    link: "ar-experience",
    description:
      " Augmented Reality can do so much more than simply entertain, it offers a wide range of marketing opportunities and a brand differentiator.",
    image: "/assets/img/previews/ar-mkt.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 6,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 pl50">
        <div class="tright p25p">
          <div class="category">APP</div>
          <div class="title-project">AR EXPERIENCE</div>
          <div class="subtitle-project">AR allows you to discover a completely new vision of the world that
            surrounds you.</div>
          <div class="subtitle-project">In the last couple of years, Augmented Reality (AR) has turned from a
            science fiction idea into an actual technology
            that is just a few taps away from the users. Almost every single new smartphone device sold today
            (whether it’s an iOS
            or Android device) is compatible with AR, which offers lots of brand-new opportunities to companies and
            developers that
            want to squeeze all its potential.</div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 d-flex align-items-end">
        <img src="/assets/img/glasses/563214-pl-2-e-6-e-210.png" srcset="/assets/img/glasses/563214-pl-2-e-6-e-210@2x.png 2x,
                      /assets/img/glasses/563214-pl-2-e-6-e-210@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <div class="text-center">
          <div class="subtitle-project">Despite what people may think, AR can do so much more than simply entertain:
            it can create marketing opportunities and
            become a brand differentiator.
            Apart from the different AR projects that we’ve developed in UST (such as AR TV Picker, Card Scanner or
            Interior
            Planning), we have also managed to create two different approaches at AR that can be escalated to any
            level.
          </div>
        </div>
      </div>
    </div>
    <div class="row py-4">
      <div class="col-md-4 col-12 text-center text-md-right mt-auto pb-3">
        <div class="subtitle-project">AR VIRTUAL SHOP is a solution that brings any shop to the comfort and
          convenience of the user’s house. With a few taps,
          the user will be able to see different products and interact with them, like glasses or cars.
        </div>
      </div>
      <div class="col-md-8 col-12 text-center">
        <img src="/assets/img/glasses/group-6.png" srcset="/assets/img/glasses/group-6@2x.png 2x,
                    /assets/img/glasses/group-6@3x.png 3x" class="group-img"></div>
    </div>
    <div class="row py-4">
      <div class="col-md-8 col-12 text-center">
        <img src="/assets/img/glasses/mockup-busstop.png" srcset="/assets/img/glasses/mockup-busstop@2x.png 2x,
        /assets/img/glasses/mockup-busstop@3x.png 3x" class="group-img"></div>
      <div class="col-md-4 col-12 mt-auto pb-3">
        <div class="subtitle-project">AR ENVIROMENT is an app that allows users to experience new way to interact with their environment and discover
        additional content anywhere they go. Just by staring with their phones at a movie poster, they can get more information
        out of it, like watching the trailer or a promotional video.
        </div>
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "Insurance Bot Assistant",
    link: "insurance-bot-assistant",
    description: "Chatbots could improve some of their current processes.",
    image: "/assets/img/previews/insurance-bot-assistant.jpg",
    types: ["mobile", "smart", "solution"],
    order: 1,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">BOT</div>
          <div class="title-project">Insurance Bot Assistant</div>
          <div class="subtitle-project">While ago we got a request from one of our Key clients, they wanted to
            investigate potential solutions where chatbots
            could improve some of their current processes.</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex align-items-end">
        <img src="/assets/img/insurance-bot/group-3.png" srcset="/assets/img/insurance-bot/group-3@2x.png 2x,
                      /assets/img/insurance-bot/group-3@3x.png 3x" class="group-img mx-auto mx-lg-0 ml-5">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <div class="text-center">
          <div class="subtitle-project">Business spend $1.3 trillion on customer service calls each year, these
            calls are normally boring and time consuming,
            especially if there is no one to answer your queries and you spent time hanging on the call. Over 50% of
            these calls are
            related to common and frequent problems which can be answered via self-service in most cases.
          </div>
          <div class="subtitle-project">We wanted to innovate
            around the ordinary solutions that are already available, there is a lack of solutions where the ChatBot
            is not just a
            simple user-machine interaction. We have developed an extensible POC where we explored the possibilities
            of the most
            known NLP/NLUs available.
          </div>
          <div class="subtitle-project">Our solution can handle different types of data in order to take advantage
            of applications
            like Telegram or messenger where we can share our location, pictures and all sorts of media. We built
            our own
            middleware
            capable of process these requests and translate them for NLPs to understand.
          </div>
          <div class="subtitle-project">
            This POC covers the case where a customer
            needs to report and accident where there are no third parties involved. This could be easily extended to
            any other
            potential scenarios.
          </div>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-lg-6 col-12 pb-3 mx-auto">
        <img src="/assets/img/insurance-bot/service_icon2.png" class="group-img">
      </div>
      <div class="col-lg-6 col-12 pb-3 mx-auto">
        <img src="/assets/img/insurance-bot/image002.png" class="group-img">
      </div>
      <div class="col-lg-6 col-12 pb-3 mx-auto">
        <img src="/assets/img/insurance-bot/image005.png" class="group-img">
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    references_es: true,
    references_en: true,
  },
  {
    name: "3D Plan Generator",
    link: "3d-plan-generator",
    description:
      "Accelerate and improve the quality of work, expanding the possibilities with our solution",
    image: "/assets/img/previews/3dplan.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 10,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">SOFTWARE</div>
          <div class="title-project">3D PLAN GENERATOR</div>
          <div class="subtitle-project font-weight-bold">Accelerate and improve the quality of work, expanding the
            possibilities with our solution.
          </div>
          <div class="subtitle-project">Convert your AutoCad 2D plan to 3D, keeping the same coordinates and
            dimensions and explore the environment generated
            using your mobile device.
            Thanks to Virtual Reality integration, you will be able to see your designs as never before and
            revolutionize your team
            and the way you work with a customized solution that can be scaled to any need.
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 d-flex align-items-end">
        <img src="/assets/img/3dplan/3dplanlogo.png" srcset="/assets/img/3dplan/3dplanlogo@2x.png 2x,
                                                  /assets/img/3dplan/3dplanlogo@3x.png 3x" class="group-img"></div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-5 col-12 text-center">
        <img src="/assets/img/3dplan/3dplan1.png" srcset="/assets/img/3dplan/3dplan1@2x.png 2x,
                                                              /assets/img/3dplan/3dplan1@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-7 col-12 mt-auto py-3">
        <div class="subtitle-project"><b>Aerial view</b><br>
          Easily view the map thanks to the aerial cameras that are automatically provided by 3D plan generator and
          placed
          strategically in the most important points of the plan.
        </div>
        <div class="subtitle-project">
          <b>Interior view</b><br>
          Place the camera in any part of the plane just by touching the screen. You will be able to control it and
          easily move
          around the plane.
        </div>

        <div class="subtitle-project">
          <b>VR view</b><br>
          With a DayDream device you can alternate the view mode by accessing the virtual reality mode. You can be
          inside your
          design and see it in detail with high quality graphics generated from a 2D plan.
        </div>
      </div>
    </div>
  </div>
</section>
<section class="back-color py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-5 col-12 text-md-center my-auto pb-3">
        <div class="subtitle-project"><b>Increase your productivity</b><br>
          Visualize the designed plans on real time in 3D, so that your experts can focus only on creativity and
          design.
        </div>
        <div class="subtitle-project">
          <b>Real-time updates</b><br>
          Check, add features and update the new building structure in real-time.
        </div>
        <div class="subtitle-project">
          <b>Save time</b><br>
          Improve the quality and creativity, reducing hours of workload by not having to extrude part by part the
          3D plan.
        </div>
        <div class="subtitle-project">
          <b>Be a market leader</b><br>
          Offer your customers the possibility to visualize in Virtual Reality the products that your experts
          design. If they are
          not satisfied with the results, make the changes only fixing the 2D plan.
        </div>
      </div>
      <div class="col-md-7 col-12 my-auto text-center">
        <img src="/assets/img/3dplan/3dplan2.png" srcset="/assets/img/3dplan/3dplan2@2x.png 2x,
                                                          /assets/img/3dplan/3dplan2@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>`,
    presentation: true,
    presentation_es: true,
    presentation_2020: true,
    references_es: true,
  },
  {
    name: "The AR Help APP",
    link: "ar-help",
    description: "Offer assistance in any moment.",
    image: "/assets/img/previews/ar-help.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 11,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">The AR Help APP</div>
          <div class="subtitle-project">“ARHelp!” it’s a multiplatform application that integrates augmented reality technologies into a real-time video call,
          creating a unique communication experience.</div>
          <div class="subtitle-project">Our platform offers a visual and simple communication, enriched by augmented reality, between a technician and a person who
          needs remote assistance.</div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 py-3">
        <img src="/assets/img/arhelp/arhelphome@3x.png" srcset="/assets/img/arhelp/arhelphome.png, /assets/img/arhelp/arhelphome@2x.png 2x,
                      /assets/img/arhelp/arhelphome@3x.png 3x" class="group-img mx-auto mx-lg-0 w-100">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 col-12 mt-auto py-3">
        <div class="subtitle-project">
          <b>Offer assistance at any time</b><br>
          Our solution is designed to be controlled at all time by a profesional technician, this way it will be as accesible as
          possible to the assisted person.
        </div>
        <div class="subtitle-project">
          Once the video call is established, the technician will have a series of tools to draw the different indications which
          will be transformed into 3D objects that are positioned in the real world, and can be seen by both participants of the
          call.
        </div>

        <div class="subtitle-project">
          <b>The best professional tool for your support team</b><br>
          The technician will have a drawing interface which he will use to customize, among other options, the size and color of
          the drawn objects.
        </div>
        <div class="subtitle-project">
          He will also count with some tools to control the characteristics of augmented reality. The technician can
          easily control how the indications are positioned in the real world.
        </div>
      </div>
      <div class="col-md-6 col-12 text-center">
        <img src="/assets/img/arhelp/mano1.png" srcset="/assets/img/arhelp/mano1@2x.png 2x,
                                                                            /assets/img/arhelp/mano1@3x.png 3x"
          class="group-img">
      </div>
    </div>
  </div>
</section>`,
    presentation_es: true,
    presentation: true,
    presentation_2020: true,
    references_es: true,
  },
  {
    name: "Attendance Manager",
    link: "attendance-manager",
    description: "Simplify your event management.",
    image: "/assets/img/previews/attendance-manager.jpg",
    types: ["mobile", "solution"],
    order: 12,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">Attendance Manager</div>
          <div class="subtitle-project">Simplify your event management with Attendance Manager. Scan QR codes, validate users or register new attendants onsite.</div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 py-3">
        <img src="/assets/img/attendance/tablet2.png" srcset="/assets/img/attendance/tablet2@2x.png 2x,
                                                                            /assets/img/attendance/tablet2@3x.png 3x"
          class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 col-12 my-auto py-3">
        <div class="subtitle-project">
          <!--b>Offer assistance at any time</b><br-->
          Our Attendance Manager solution is an app that simplifies and reduces the cost of managing the event attendance.
        </div>
        <div class="subtitle-project">
          The app allows you to scan generated QR codes in order to validate if a user is invited or not.
        </div>

        <div class="subtitle-project">
          <!--b>The best professional tool for your support team</b><br-->
          It also shows a filterable list of registered people which can be used to check information about any registered user and offers the possibility to register users onsite.
        </div>
        <div class="subtitle-project">
          From the app, you will be also able to check real-time statistics such as attendance level or validated people.
        </div>
      </div>
      <div class="col-md-6 col-12 text-center">
        <img src="/assets/img/attendance/tablet3.png" srcset="/assets/img/attendance/tablet3@2x.png 2x,
                                                                            /assets/img/attendance/tablet3@3x.png 3x"
          class="group-img">
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: true,
    presentation_es: false,
    presentation: false,
    presentation_2020: true,
  },
  {
    name: "Time Up",
    link: "timeup",
    description: "Register the time worked by your employees.",
    image: "/assets/img/previews/timeup.jpg",
    types: ["mobile", "solution"],
    order: 12,
    body: `    <section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">Time Up</div>
          <div class="subtitle-project">Register the time worked by your employees with Time’Up and manage all the data from a simple dashboard.</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 d-flex justify-content-between py-3">
        <img src="/assets/img/timeup/time-app.png" srcset="/assets/img/timeup/time-app@2x.png 2x,
                                                                            /assets/img/timeup/time-app@3x.png 3x"
          class="group-img">
        <img src="/assets/img/timeup/time-app2.png" srcset="/assets/img/timeup/time-app2@2x.png 2x,
                                                                            /assets/img/timeup/time-app2@3x.png 3x"
          class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-12 text-center">
        <div class="subtitle-project">
          Time’Up is a powerful and complete solution featuring an app for iOS and Android for employees, as well as a web dashboard for companies.
        </div>
        <div class="subtitle-project">
          Using the app, employees can register their work time from any predefined location, such as work, home or a customer’s office.
        </div>
        <div class="subtitle-project">
          On the other hand, the scalability of our solution provides companies with a dashboard that allows them to customize the entire platform and access features such as:
        </div>
      </div>
      <div class="col-md-5 col-12 d-flex justify-content-between text-center">
        <img style="max-width:300px;" src="/assets/img/timeup/settings.png" srcset="/assets/img/timeup/settings@2x.png 2x,
                                                                            /assets/img/timeup/settings@3x.png 3x"
          class="group-img mx-auto">
      </div>
      <div class="col-md-7 col-12 my-auto py-3">
        <div class="subtitle-project">
          <b>Defining a work-schedule for each employee</b><br>
          Not every worker has the same schedule, so Time’Up can easily adapt to reflect summer schedules, shorter Fridays, holidays, etc.
        </div>
        <div class="subtitle-project">
          <b>Authorizing locations for each employee</b><br>
          Companies can limit the location from where their employees work or let them work from anywhere.
        </div>
        <div class="subtitle-project">
          <b>Establish the level of location tracking</b><br>
          If a company decides to set fixed locations for their employees, a location tracking that checks if the user is at set location can be established.
        </div>
        <div class="subtitle-project">
          <b>Seeing the employees’ registrations</b><br>
          The dashboard enables companies to access all the entries that users register at any time.
        </div>
        <div class="subtitle-project">
          <b>Generating weekly, monthly and yearly user reports</b><br>
          Reports that gather all the worked time are quite handy and our solution provides weekly, monthly and yearly reports.
        </div>
        <div class="subtitle-project">
          <b>Complying with the labor laws and regulations</b><br>
          In case your business needs to comply with certain labor laws and regulations, we can adapt our solution to make sure all the data is safely stored and can be accessed by both employees and employers.
        </div>
      </div>
      <div class="col-12 my-3 text-center">
        <div class="subtitle-project">
          All these features are open and can be adapted to each company in case it’s needed.
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <img style="max-width:300px;" src="/assets/img/timeup/location.png" srcset="/assets/img/timeup/location@2x.png 2x,
                                                                            /assets/img/timeup/location@3x.png 3x"
          class="group-img mt-2">
        <img style="max-width:300px;" src="/assets/img/timeup/location_choose.png" srcset="/assets/img/timeup/location_choose@2x.png 2x,
                                                                            /assets/img/timeup/location_choose@3x.png 3x"
          class="group-img mt-2">
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
    presentation_2020: true,
  },
  {
    name: "AR Navigation",
    link: "ar-navigation",
    description: "Navigate anywhere you want.",
    image: "/assets/img/previews/ar-navigation.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 13,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">APP</div>
          <div class="title-project">AR Navigation</div>
          <div class="subtitle-project">AR Navigation is a multiplatform application that integrates Augmented Reality technology with a real-time user positioning system.</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 py-3">
        <img src="/assets/img/arnav/arnavmano.png" srcset="/assets/img/arnav/arnavmano@2x.png 2x,
            /assets/img/arnav/arnavmano@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-12 my-auto py-3">
        <div class="subtitle-project">
          Our solution features a simple and intuitive interface that offers an indoor positioning system that doesn’t depend on GPS signal nor beacon points. Using only our application, you can position yourself and locate destinations within any building.
          AR Navigation offers both visual and sound-based assistance, which helps to guide people with visual disabilities to their destination.
        </div>
        <div class="subtitle-project">
          Any company that has a large area open to the public can easily increase customer acquisition and loyalty thanks to the use of ArNavigation, making its products much more accessible to its customers. From shopping centers and supermarkets, to national parks, amusement parks, hospitals or airports.
ArNavigation can also be used by large companies that want to install it in their offices, factories or storage sites, to increase the productivity of their employees by easing their daily work.
        </div>
        <div class="subtitle-project text-center">
          <b>NAVIGATION IS JUST THE BEGINNING</b>
        </div>
        <div class="subtitle-project">
          Navigation and location through Augmented Reality (AR) is only a glimpse of our solution’s potential. Once the user has entered the ‘AR world’, companies will be able to show announcements or information about products or services directly on the world that the user is navigating through, among many other things. This solution can be easily scaled to any customer needs, creating an "ecosystem" of Augmented Reality features and services.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 py-3 col-12 text-center">
        <img src="/assets/img/arnav/arnav1.png" srcset="/assets/img/arnav/arnav1@2x.png 2x,
                                                                            /assets/img/arnav/arnav1@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-4 py-3 col-12 text-center">
        <img src="/assets/img/arnav/arnav2.png" srcset="/assets/img/arnav/arnav2@2x.png 2x,
                                                                            /assets/img/arnav/arnav2@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-4 py-3 col-12 text-center">
        <img src="/assets/img/arnav/arnav3.png" srcset="/assets/img/arnav/arnav3@2x.png 2x,
                                                                            /assets/img/arnav/arnav3@3x.png 3x"
          class="group-img">
      </div>
    </div>
    <div class="row py-5 overflow-auto">
      <div class="col-12 text-center">
        <iframe width="536" height="315" src="https://www.youtube.com/embed/NGwIXf8ftJ4">
        </iframe>
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
  },
  {
    name: "3D Reality",
    link: "3d-reality",
    description: "Bring a new dimension to your web and mobile applications",
    image: "/assets/img/previews/3d-reality.jpg",
    types: ["mobile", "augmented", "solution"],
    order: 14,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">WEB</div>
          <div class="title-project">3D Reality</div>
          <div class="subtitle-project">3D Reality is the result of our ability to create 3D environments of augmented reality or virtual reality oriented to provide a web or mobile application of this innovative technology</div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 py-3">
        <img src="/assets/img/3dreality/3d-reality.png" srcset="/assets/img/3dreality/3d-reality@2x.png 2x,
            /assets/img/3dreality/3d-reality@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
    <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/3dreality/3D-Reality-2.png" srcset="/assets/img/3dreality/3D-Reality-2@2x.png 2x,
                                                                            /assets/img/3dreality/3D-Reality-2@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-6 col-12 my-auto py-3">
        <div class="subtitle-project">
          Our solution allows the incorporation of hosts, with which it is possible to interact and dialogue as a virtual assistant, thanks to this, this solution can be applied to a wide range of use cases
        </div>
        <div class="subtitle-project">
          <b>3D Web</b><br>
          Grant the ability of 3D to a 2D environment, use a 3D visualizer and configurator of products for your e-commerce, provide 3D objects for your learning platform to improve knowledge retention or create factory replicas to give greater knowledge of your company's employees.
        </div>
        <div class="subtitle-project">
          <b>Augmented Reality</b><br>
          Thanks to billions of devices with AR capability, it is easier to bring augmented reality from business to customers, improve the way you sell your products so that customers can try them at home before buying them, you can also improve support services by providing diagnostic metrics or animations to repair objects in your own home.
        </div>
        <div class="subtitle-project">
          <b>Virtual Reality</b><br>
          Create 3D environments or 360-degree video, thanks to VR you can create completely immersive environments, provides its employees with virtual learning environments or for surgeons, also visualize your company's data in an immersive environment or improve the customer experience of your hotel.
        </div>
      </div>

    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
    presentation_2020: true,
  },
  {
    name: "Onboarding web template",
    link: "onboarding",
    description: "A template to use for onboarding sites",
    image: "/assets/img/previews/onboarding.jpg",
    types: ["ux"],
    order: 15,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">UX/UI</div>
          <div class="title-project">Onboarding web template</div>
          <div class="subtitle-project">A template to use for onboarding sites</div>
          <div class="subtitle-project">As part of our internal projects we developed a new website to act as the entry point for every new employee. This onboarding website offers the company the option to add as many steps into the path as they want. Scoreboards for users, contacts and lots more</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/onboarding/imgHeader.png" srcset="/assets/img/onboarding/imgHeader@2x.png 2x,
            /assets/img/onboarding/imgHeader@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/onboarding/lesson.png" srcset="/assets/img/onboarding/lesson@2x.png 2x,
                                                                            /assets/img/onboarding/lesson@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-6 col-12 mt-5 py-3">
        <div class="subtitle-project">
          <b>Designed tailored to your needs</b><br>
          We can adapt our designs to the look and feel of your company. Everything has been build in a modular way so companies can choose what they really want to add into their projects
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-7 py-3 col-12 text-center">
        <img src="/assets/img/onboarding/perfil.png" srcset="/assets/img/onboarding/perfil@2x.png 2x,
                                                                            /assets/img/onboarding/perfil@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-5 col-12 onboarding-img-up">
        <img src="/assets/img/onboarding/contactos.png" srcset="/assets/img/onboarding/contactos@2x.png 2x,
          /assets/img/onboarding/contactos@3x.png 3x" class="group-img">
      </div>

    </div>
  </div>
</section>
<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="py-5 text-center">
        <div class="subtitle-project"><b>Design and develop new funcionalities</b><br>
          We offer the option to request new features, they will be include into our library of components for further reutilization
        </div>
      </div>
    </div>
    <div class="row">
      <img src="/assets/img/onboarding/leaderChat.png" srcset="/assets/img/onboarding/leaderChat@2x.png 2x,
          /assets/img/onboarding/leaderChat@3x.png 3x" class="group-img">
    </div>
  </div>
</section>`,
    references_es: false,
    references_en: false,
    presentation_es: false,
    presentation: false,
    presentation_2020: true,
  },
  {
    name: "Adecco´s products redesign",
    link: "adecco",
    description: "A template to use for onboarding sites",
    image: "/assets/img/previews/adecco.jpg",
    types: ["ux"],
    order: 16,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">UX/UI</div>
          <div class="title-project">Adecco´s products redesign</div>
          <div class="subtitle-project">Complete redesign of the Adecco mobile app. Creation of a web for the workers.</div>
          <div class="subtitle-project">As part of a bigger proposal we took the challenge of redesigning Adecco´s consumer application. We went through the process of envisioning new capabilities and researching the competitors to create the best product on the market.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/onboarding/imgHeader.png" srcset="/assets/img/onboarding/imgHeader@2x.png 2x,
            /assets/img/onboarding/imgHeader@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/adecco/group-783.png" srcset="/assets/img/adecco/group-783@2x.png 2x,
                                                                            /assets/img/adecco/group-783@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-6 col-12 mt-5 py-3">
        <div class="subtitle-project">
          <b>New version of the mobile application</b><br>
          Here you can see a small example of the new screens designed. Everything we did was very user centric and powered by artificial intelligence services capable of provide the most relevant information for each case.
        </div>
      </div>
    </div>
  </div>
</section>
<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="py-5 text-center">
        <div class="subtitle-project"><b>Adecco´s university website</b><br>
          As part of the project we also designed a new website to act as repository of all the technical documentation as well as being the hub for all the on boarding processes.
        </div>
      </div>
    </div>
    <div class="row my-3">
      <img src="/assets/img/adecco/imgPC.png" srcset="/assets/img/adecco/imgPC@2x.png 2x,
          /assets/img/adecco/imgPC@3x.png 3x" class="group-img mx-auto">
    </div>
    <div class="row">
      <img src="/assets/img/adecco/imgBottom.png" srcset="/assets/img/adecco/imgBottom@2x.png 2x,
          /assets/img/adecco/imgBottom@3x.png 3x" class="group-img mx-auto">
    </div>
  </div>
</section>`,
    references_es: false,
    references_en: false,
    presentation_es: false,
    presentation: false,
  },
  {
    name: "Mutua Madrid Open",
    link: "mutua-madrid-open",
    description: "A new way to enjoy the Mutua Madrid Open",
    image: "/assets/img/previews/mutua.jpg",
    types: ["ux"],
    order: 17,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">UX/UI</div>
          <div class="title-project">Mutua Madrid Open</div>
          <div class="subtitle-project">A new way to enjoy the Mutua Madrid Open</div>
          <div class="subtitle-project">We have created a new concept for the Mutua Madrid Open application. We believe the current application din´t offer the best user experience.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/mutua/open-mutua-madrid-sin-fondo-1.png" srcset="/assets/img/mutua/open-mutua-madrid-sin-fondo-1@2x.png 2x,
            /assets/img/mutua/open-mutua-madrid-sin-fondo-1@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="">
  <div class="container">
    <div class="row">
      <div class="py-5 text-center">
        <div class="subtitle-project"><b>Augmented Reality</b><br>
          As part of the new design we also introduced the idea of using Augmented Reality to guide people through the stadium. For example to guide them to the food truck were they need to collect the order placed.
        </div>
      </div>
    </div>
    <div class="row my-3">
      <img src="/assets/img/mutua/group-782.png" srcset="/assets/img/mutua/group-782.png 1x, /assets/img/mutua/group-782@2x.png 2x,
          /assets/img/mutua/group-782@3x.png 3x" class="group-img mx-auto">
    </div>
  </div>
</section>`,
    references_es: false,
    references_en: false,
    presentation_es: false,
    presentation: false,
  },
  {
    name: "UCI WEBSITE REDESIGN",
    link: "uci-website-redesign",
    description: "Complete redesing of UCI´s websites",
    image: "/assets/img/previews/uci.jpg",
    types: ["ux"],
    order: 18,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">UX/UI</div>
          <div class="title-project">UCI WEBSITE REDESIGN</div>
          <div class="subtitle-project">Complete redesing of UCI´s websites</div>
          <div class="subtitle-project">As part of the project the biggest task was to combine more than 15 different websites from the same company into a single one.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/uci/group-781.png" srcset="/assets/img/uci/group-781@2x.png 2x,
            /assets/img/uci/group-781@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/uci/style-guide.png" srcset="/assets/img/uci/style-guide@2x.png 2x,
                                                                            /assets/img/uci/style-guide@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-6 col-12 mt-5 py-3">
        <div class="subtitle-project">
          <b>Style guide</b><br>
          We created a complete style guide document for UCI to improve upon their future projects.
        </div>
      </div>
    </div>
  </div>
</section>
<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="py-5 text-center">
        <div class="subtitle-project"><b>Components library</b><br>
          As part of the deliverable assets of the project we created a repository of components to build the website.
        </div>
      </div>
    </div>
    <div class="row">
      <img src="/assets/img/uci/group-786.png" srcset="/assets/img/uci/group-786@2x.png 2x,
          /assets/img/uci/group-786@3x.png 3x" class="group-img mx-auto">
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
  },
  {
    name: "PROA WEBSITE",
    link: "proa-ocasion",
    description: "Complete develop of PROA webpage",
    image: "/assets/img/previews/proa-ocasion.jpg",
    types: ["mobile"],
    order: 19,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">WEB</div>
          <div class="title-project">PROA WEBSITE</div>
          <div class="subtitle-project">Complete develop of PROA webpage</div>
          <div class="subtitle-project">Web layout work and subsequent integration with services for PROA</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/proa/imgPC.png" srcset="/assets/img/proa/imgPC@2x.png 2x,
            /assets/img/proa/imgPC@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/proa/image-9.png" srcset="/assets/img/proa/image-9@2x.png 2x,
        /assets/img/proa/image-9@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/proa/image-8.png" srcset="/assets/img/proa/image-8@2x.png 2x,
          /assets/img/proa/image-8@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/proa/image-7.png" srcset="/assets/img/proa/image-7@2x.png 2x,
          /assets/img/proa/image-7@3x.png 3x" class="group-img">
      </div>
      <div class="col-md-6 py-3 col-12 text-center">
        <img src="/assets/img/proa/image-11.png" srcset="/assets/img/proa/image-11@2x.png 2x,
          /assets/img/proa/image-11@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
  },
  {
    name: "Chatbot Assistant",
    link: "chatbot",
    description: "UST Office Assistant is a conversational assistant",
    image: "/assets/img/previews/chatbot.jpg",
    types: ["smart", "solution"],
    order: 19,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">CHATBOT</div>
          <div class="title-project">Chatbot Assistant</div>
          <div class="subtitle-project">UST Office Assistant is a conversational assistant that remove barriers between people and technology through a natural and fluid dialogue.</div>
          <div class="subtitle-project">We improve customer service, sales support and the image of your company through our conversational assistants and chatbots by offering a better user experience: faster, simpler, truthful responses, 24x7 availability.</div>
          <div class="subtitle-project">The UST Office Assistant allows potential customers to ask questions about your company: how you work, what projects you carry out, what solutions you could offer.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/chatbot/chatbot1.png" srcset="/assets/img/chatbot/chatbot1@2x.png 2x,
            /assets/img/chatbot/chatbot1@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
        <div class="col-md-5 col-12 text-center">
          <img src="/assets/img/chatbot/chatbot2.png" srcset="/assets/img/chatbot/chatbot2@2x.png 2x,
                                                                /assets/img/chatbot/chatbot2@3x.png 3x"
            class="group-img">
        </div>
        <div class="col-md-7 col-12 py-3">
          <div class="subtitle-project">
            Also, the UST Office Assistant enables the resolution of initial doubts that any employee newly incorporated into the company may have: work schedule, flexible remuneration, payroll consultation, telework request, material request… It can solve too the most complex doubts that arise to any employee: research programs, work trips, medical discharges, etc.
          </div>
          <div class="subtitle-project">
            This automation would save time both for departments that receive queries that do not correspond to them, and for users, as direct answers regarding how to proceed are given. A minimum user-chatbot interaction can resolve a lot of questions.
          </div>
          <div class="subtitle-project">
            Besides, the integration in platforms such as Alexa, Google Assistant or Facebook would make an easier and more versatile access to your content. Enabling voice interaction will facilitate access to information for people with functional diversity. At the same time, it allows to define a company image through the personality of the assistant.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>`,
    references_es: true,
    references_en: false,
    presentation_es: false,
    presentation: false,
    presentation_2020: true,
  },
  {
    name: "CAREGIVER",
    link: "caregiver",
    description: "The website to help caregivers",
    image: "/assets/img/previews/caregiver.jpg",
    types: ["ux", "solution"],
    order: 20,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">UX/UI & WEB</div>
          <div class="title-project">CAREGIVER</div>
          <div class="subtitle-project">Caregiver is an innovative platform designed to help those who work taking care of others.</div>
          <div class="subtitle-project">We have created a new way of understanding caring and facilitating caregivers'
            work.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3">
        <img src="/assets/img/caregiver/home.png" srcset="/assets/img/caregiver/home@2x.png 2x,
                  /assets/img/caregiver/home@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-5 col-12 text-center">
        <img src="/assets/img/caregiver/pic1.png" srcset="/assets/img/caregiver/pic1@2x.png 2x,
                                                                      /assets/img/caregiver/pic1@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-7 col-12 py-3">
        <div class="subtitle-project">
          <b>Designed tailored to your needs</b>
        </div>
        <div class="subtitle-project">
          We created a modern and intuitive user interface. A well-thought-out visual hierarchy allows the user to quickly understand the system and save time and effort needed to complete tasks.
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-3">
      <div class="col-md-6 col-12 text-center">
        <img src="/assets/img/caregiver/pic2.png" srcset="/assets/img/caregiver/pic2@2x.png 2x,
                                                                          /assets/img/caregiver/pic2@3x.png 3x"
          class="group-img">
      </div>
      <div class="col-md-6 col-12 text-center">
        <img src="/assets/img/caregiver/pic3.png"
          srcset="/assets/img/caregiver/pic3@2x.png 2x,
                                                                                  /assets/img/caregiver/pic3@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="back-color py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item1.png"
          srcset="/assets/img/caregiver/item1@2x.png 2x, /assets/img/caregiver/item1@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">Phisical Health</div>
        <div class="items-text text-center">Everything you need to know about the physical and health condition of  the person taken care of.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item2.png"
          srcset="/assets/img/caregiver/item2@2x.png 2x, /assets/img/caregiver/item2@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">Mental health is very important</div>
        <div class="items-text text-center">You can have a record of the mental health.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item3.png"
          srcset="/assets/img/caregiver/item3@2x.png 2x, /assets/img/caregiver/item3@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">Always have a list of medications</div>
        <div class="items-text text-center">Thanks to our automatic reminders the caregiver will never forget to give a medication.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item4.png"
          srcset="/assets/img/caregiver/item4@2x.png 2x, /assets/img/caregiver/item4@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">User's journey</div>
        <div class="items-text text-center">Before the caregiver's first day, he will be able to know all about the person he will be taking care of as well as his day to day routine.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item5.png"
          srcset="/assets/img/caregiver/item5@2x.png 2x, /assets/img/caregiver/item5@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">Essential Documents</div>
        <div class="items-text text-center">You need to have to offer the best caregiving possible.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-5">
        <img src="/assets/img/caregiver/item6.png"
          srcset="/assets/img/caregiver/item6@2x.png 2x, /assets/img/caregiver/item6@3x.png 3x" class="group-img">
        <div class="items-text my-3 font-weight-bold text-center">Track health info</div>
        <div class="items-text text-center">If your user has to monitor their health, you can keep a complete record to make sure that the family or doctor have this information when they'll need it.</div>
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-12 text-center">
        <div class="subtitle-project">
          <b>Profile detail</b>
        </div>
        <div class="subtitle-project">
          Our platform is highly customisable depending on the use case. We will tailor it to your needs so you only need to worry about taking care.
        </div>
      </div>
      <div class="col-lg-4 col-12 py-3 d-flex">
        <img src="/assets/img/caregiver/pic4.png"
          srcset="/assets/img/caregiver/pic4@2x.png 2x, /assets/img/caregiver/pic4@3x.png 3x" class="group-img mx-auto">
      </div>
      <div class="col-lg-4 col-12 py-3 d-flex">
        <img src="/assets/img/caregiver/pic5.png"
          srcset="/assets/img/caregiver/pic5@2x.png 2x, /assets/img/caregiver/pic5@3x.png 3x" class="group-img mx-auto">
      </div>
      <div class="col-lg-4 col-12 py-3 d-flex">
        <img src="/assets/img/caregiver/pic6.png"
          srcset="/assets/img/caregiver/pic6@2x.png 2x, /assets/img/caregiver/pic6@3x.png 3x" class="group-img mx-auto">
      </div>
    </div>
  </div>
</section>`,
    references_es: false,
    references_en: false,
    presentation_es: false,
    presentation: true,
    presentation_2020: false,
  },
  {
    name: "OFFICE BOOKING",
    link: "office-booking",
    description: "Book your place at the office",
    image: "/assets/img/previews/office-booking.jpg",
    types: ["mobile", "solution"],
    order: 21,
    body: `<section class="back-color">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 pl-5 my-auto">
        <div class="text-right py-5">
          <div class="category">App</div>
          <div class="title-project">OFFICE BOOKING</div>
          <div class="subtitle-project">The tool you need to book your place at the office and a parking spot. Stay safe and make your employees be safe.</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 py-3 text-center">
        <img src="/assets/img/office-booking/i-phone-xs-real.png" srcset="/assets/img/office-booking/i-phone-xs-real@2x.png 2x,
                  /assets/img/office-booking/i-phone-xs-real@3x.png 3x" class="group-img">
      </div>
    </div>
  </div>
</section>
<section class="py-5">
  <div class="container">
    <div class="row py-3">
      <div class="col-md-5 col-12 text-center">
        <div class="row">
          <div class="col-6">
          <img src="/assets/img/office-booking/reserva.png" srcset="/assets/img/office-booking/reserva@2x.png 2x,
              /assets/img/office-booking/reserva@3x.png 3x" class="group-img">
          </div>
          <div class="col-6">
            <img src="/assets/img/office-booking/reservas-activas.png" srcset="/assets/img/office-booking/reservas-activas@2x.png 2x,
                /assets/img/office-booking/reservas-activas@3x.png 3x" class="group-img">
          </div>
        </div>
      </div>
      <div class="col-md-7 col-12 py-3">
        <div class="subtitle-project">
          <b>Office Booking</b> is an innovate reservation solution. Each screen was designed in a way so that the application was intuitive and easy for users and effective for business purposes.
        </div>
        <div class="subtitle-project">
          All the components of UI are clean, simple and adjustable. All these features are open and can be adapted to each company in case it’s needed.
        </div>
      </div>
    </div>
  </div>
</section>
<section class="back-color py-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
          <img src="/assets/img/office-booking/group-814.png"
            srcset="/assets/img/office-booking/group-814@2x.png 2x, /assets/img/office-booking/group-814@3x.png 3x" class="group-img">
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="subtitle-project">
          <b>Our benefits</b>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-4 text-center">
        <div class="subtitle-project my-2"><b><u>RESERVATIONS</u></b></div>
        <div class="subtitle-project">Booking a place in the office or parking in a few clicks. It can be done in a matter
            of seconds.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-4 text-center">
        <div class="subtitle-project my-2"><b><u>MANAGE RESERVATIONS</u></b></div>
        <div class="subtitle-project ">All reservations are saved in the application.
          A reservation can easily deleted from  reservation details.</div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 d-flex flex-column my-4 text-center">
        <div class="subtitle-project my-2"><b><u>INMEDIATE CONFIRMATION</u></b></div>
        <div class="subtitle-project ">Employees can know the status of their reservation in real time.
      </div>
      </div>
    </div>
  </div>
</section>
`,
    references_es: false,
    references_en: false,
    presentation_es: false,
    presentation: false,
    presentation_2020: false,
  },
];
