export const carousel_data = [
  {
    id: 1,
    src: "/assets/img/home/madrid-img.png",
    alt: "madrid-img",
    heading: "UST Spain",
    paragraph:
      "At UST, our work is powered by a nimble, highly tuned, organic, digital innovation assurance approach built from diverse experiences around the world, intense collaboration, and intuitive, scalable techniques and methods. \n \n We apply an empathy-first design thinking philosophy to formulate solutions customized to our clients’ needs and schedules",
  },
  {
    id: 2,
    src: "/assets/img/home/pulse-img.png",
    alt: "pulse-img",
    heading: "Pulse",
    paragraph:
      "UST Pulse wants to help you boost the user engagement of your meetings. Gather feedback in real time was never this easy, our set of tools available keeps growing with each new version.",
  },
  {
    id: 3,
    src: "/assets/img/home/innovation-img.png",
    alt: "innovation-img",
    heading: "",
    paragraph: "",
  },
];
