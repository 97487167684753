import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "projects/:link",
    loadChildren: () =>
      import("./modules/project/project.module").then((m) => m.ProjectModule),
  },
  {
    path: "products/:link",
    loadChildren: () =>
      import("./modules/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled", // Add options right here
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
