import { Component } from "@angular/core";
import { carousel_data } from "./const/carousel-data";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent {
  carouselData: any;

  constructor() {
    this.carouselData = carousel_data;
  }
}
