import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { options_select } from "./const/options-select";
import { OptionsSelect } from "./models/options-select.interface";

declare const $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentValue: OptionsSelect;
  dropdownOpen: boolean = false;
  menuOpen: boolean = false;
  options: OptionsSelect[] = options_select;
  isHome: boolean = true;
  navigateTo: string = "";
  private listener: () => void;

  @ViewChild("dropdown", { static: false }) elem!: ElementRef;

  constructor(private renderer: Renderer2, private router: Router) {}

  ngOnInit(): void {
    this.currentValue = this.options[0];
    this.listener = this.renderer.listen("window", "click", (e: Event) => {
      if (!this.elem.nativeElement.contains(e.target)) {
        this.dropdownOpen = false;
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navigateTo = localStorage.getItem("lastSection");
        this.isHome = event.urlAfterRedirects === "/home" ? true : false;
        if (this.isHome && this.navigateTo !== "") {
          setTimeout(() => {
            this.scroll(this.navigateTo);
          }, 50);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.listener();
    localStorage.removeItem("currentTab");
    localStorage.removeItem("lastSection");
  }

  scroll(el: string, tab?: string): void {
    // console.log(this.isHome, el, tab);

    if (tab === "innovation") {
      localStorage.setItem("currentTab", "innovation");
    } else if (tab === "products") {
      localStorage.setItem("currentTab", "products");
    }

    if (this.isHome && el && $("section#" + el)[0]) {
      $("section#" + el)[0].scrollIntoView();
      this.navigateTo = "";
    } else {
      this.router.navigate(["home"]);
      localStorage.setItem("lastSection", el);
      this.navigateTo = el;
    }
  }

  closeDropdown() {
    this.elem.nativeElement.setAttribute("aria-expanded", "false");
    this.dropdownOpen = false;
  }

  selectByIndex(i: number) {
    let value = this.options[i];
    this.select(value);
  }

  select(value: OptionsSelect) {
    this.currentValue = value;
    this.closeDropdown();
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.elem.nativeElement.setAttribute(
      "aria-expanded",
      this.dropdownOpen ? "true" : "false"
    );
  }
}
