import { Injectable } from "@angular/core";
import { projects_data } from "../../../assets/data/projects-data.const";
import { products_data } from "../../../assets/data/products-data.const";
import { project_use_cases } from "../../../assets/data/project-use-cases.const";
import { UseCases } from "../models/use-cases.interface";
import { Product } from "../models/product.interface";
import { Project } from "../models/project.interface";

@Injectable({
  providedIn: "root",
})
export class DataService {
  projects: Project[];
  products: Product[];
  use_cases: UseCases[];

  constructor() {
    this.projects = projects_data;
    this.products = products_data;
    this.use_cases = project_use_cases;
  }

  // PROJECTS
  getProjects(): Project[] {
    return this.projects;
  }

  getProjectByLink(link: string): Project {
    return this.projects.filter((x) => x.link === link)[0];
  }

  getProjectUseCases(name: string) {
    return this.use_cases.filter((x) => x.project === name)[0]
      ? this.use_cases.filter((x) => x.project === name)[0].use_cases
      : null;
  }

  // PRODUCTS
  getProducts(): Product[] {
    return this.products;
  }

  getProductByLink(link: string): Product {
    return this.products.filter((x) => x.link === link)[0];
  }

  // OTHER PROJECTS AND PRODUCTS SECTION
  getRelatedProjectsAndProducts(
    link: string,
    projects: (Product | Project)[]
  ): (Product | Project)[] {
    let index = projects.findIndex((x) => x.link === link);

    let index_2 = index - 2;
    let index_1 = index - 1;
    let index1 = index + 1;
    let index2 = index + 2;
    return [
      projects[index_2 + (index_2 < 0 ? projects.length : 0)],
      projects[index_1 + (index_1 < 0 ? projects.length : 0)],
      projects[index1 - (index1 >= projects.length ? projects.length : 0)],
      projects[index2 - (index2 >= projects.length ? projects.length : 0)],
    ];
  }
}
