import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() type: string = "";
  @Input() message: string = "";
  @Input() disabledForm: FormControl;
  @Input() ImgSrc: string = "";
  @Input() ImgAlt: string = "";

  constructor() {}
}
