import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { ButtonComponent } from "./components/button/button.component";
import { CardComponent } from "./components/card/card.component";
import { SafePipe } from "../core/pipes/safe.pipe";
import { MoreProjectsComponent } from "./components/more-projects/more-projects.component";
import { BackButtonComponent } from "./components/back-button/back-button.component";

const sharedComponents = [
  FooterComponent,
  HeaderComponent,
  CarouselComponent,
  ButtonComponent,
  MoreProjectsComponent,
  BackButtonComponent,
  CardComponent,
  SafePipe,
];
@NgModule({
  imports: [RouterModule, CommonModule, ReactiveFormsModule],
  exports: [RouterModule, CommonModule, ...sharedComponents],
  declarations: [...sharedComponents],
})
export class SharedModule {}
