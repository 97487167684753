import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

declare const $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "smart-web";
  isHome: boolean = true;

  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isHome = (() => val.url === "/")();
      }
    });
  }
}
